import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { handleDownloadFile } from 'services/utils/downloadFile';
import axios from 'axios';
import moment from 'moment';

import {
	cloneMessageRequest,
	cloneMessageSuccess,
	createMessageError,
	createMessageRequest,
	createMessageSuccess,
	deleteFilesRequest,
	deleteFilesSuccess,
	deleteMessageRequest,
	downloadFilesRequest,
	downloadFilesSuccess,
	getMessagesRequest,
	getMessagesSuccess,
	getOneMessageRequest,
	getOneMessageSuccess,
	updateMessageError,
	updateMessageRequest,
	updateMessageSuccess,
} from './reducer';

import {
	IApiGetOneMessageParams,
	IMessage,
	IMessageCreateRequestPayload,
	IMessageDeleteFilesRequestPayload,
	IMessageDeleteRequestPayload,
	IMessageDownloadFilesRequestPayload,
	IMessageRequestPayload,
	IMessageUpdateRequestPayload,
	IMessageWithPagination,
} from './types';
import { popUpOpen, setPopUpData } from '../popUp/reducer';

function* getMessagesWorker({ payload }: PayloadAction<IMessageRequestPayload>) {
	try {
		const data: IMessageWithPagination = yield call(api.messages.getMessages, payload);

		yield put(getMessagesSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOneMessageWorker({ payload }: PayloadAction<IApiGetOneMessageParams>) {
	try {
		const data: IMessage = yield call(api.messages.getOneMessage, payload);

		yield put(getOneMessageSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createMessageWorker({ payload }: PayloadAction<IMessageCreateRequestPayload>) {
	try {
		const { onCloseForm, sent, type } = payload;
		const response: IMessage = yield call(api.messages.createMessage, payload);

		yield put(createMessageSuccess());
		yield put(popUpOpen('successPopUp'));
		yield put(
			setPopUpData({
				title: `Message ${sent ? 'sent' : 'scheduled'}`,
				message: sent
					? `Message ID ${response.id} was sent successfully.`
					: `Message ID ${response.id} was scheduled at ${moment(response.planned_on).format(
							'hh:mm',
					  )}, ${moment(response.planned_on).format('DD MMM, YYYY')}.`,
			}),
		);

		const data: IMessageWithPagination = yield put(
			getMessagesRequest({ per_page: 10, current_page: 1, type }),
		);
		yield put(getMessagesSuccess(data));

		yield call(onCloseForm);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(createMessageError());
	}
}

function* deleteMessageWorker({ payload }: PayloadAction<IMessageDeleteRequestPayload>) {
	try {
		const { onCloseForm, id, type } = payload;

		yield call(api.messages.deleteMessage, payload);
		yield put(createMessageSuccess());
		yield put(popUpOpen('successPopUp'));
		yield put(
			setPopUpData({
				title: 'Message deleted',
				message: `Message ID ${id} was deleted successfully.`,
			}),
		);

		const data: IMessageWithPagination = yield put(
			getMessagesRequest({ per_page: 10, current_page: 1, type }),
		);
		yield put(getMessagesSuccess(data));

		if (onCloseForm) {
			yield call(onCloseForm);
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* updateMessageWorker({ payload }: PayloadAction<IMessageUpdateRequestPayload>) {
	try {
		const { onCloseForm, type } = payload;
		yield call(api.messages.updateMessage, payload);

		yield put(updateMessageSuccess());
		yield put(popUpOpen('successPopUp'));
		yield put(
			setPopUpData({
				title: 'Message updated',
				message: `Message ID ${payload.id} was updated successfully.`,
			}),
		);

		const data: IMessageWithPagination = yield put(
			getMessagesRequest({ per_page: 10, current_page: 1, type }),
		);
		yield put(getMessagesSuccess(data));

		yield call(onCloseForm);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(updateMessageError());
	}
}

function* deleteFilesWorker({ payload }: PayloadAction<IMessageDeleteFilesRequestPayload>) {
	try {
		yield call(api.messages.deleteFiles, payload);
		yield put(deleteFilesSuccess());

		const data: IMessage = yield call(api.messages.getOneMessage, { messageId: payload.id });
		yield put(getOneMessageSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* downloadFilesWorker({ payload }: PayloadAction<IMessageDownloadFilesRequestPayload>) {
	try {
		const data: string = yield call(api.messages.downloadFiles, payload);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const fileName = `${payload.file.split('/').pop()}.${payload.file.split('.').pop()}`;

		handleDownloadFile(data, fileName);

		yield put(downloadFilesSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			// responseErrors(error);
		}
	}
}

function* cloneMessageWorker({ payload }: PayloadAction<IMessageDeleteRequestPayload>) {
	try {
		const data: IMessage = yield call(api.messages.cloneMessage, payload);

		yield put(getOneMessageSuccess(data));
		yield put(cloneMessageSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* messagesSaga() {
	yield takeLatest(getMessagesRequest, getMessagesWorker);
	yield takeLatest(getOneMessageRequest, getOneMessageWorker);
	yield takeLatest(createMessageRequest, createMessageWorker);
	yield takeLatest(deleteMessageRequest, deleteMessageWorker);
	yield takeLatest(updateMessageRequest, updateMessageWorker);
	yield takeLatest(deleteFilesRequest, deleteFilesWorker);
	yield takeLatest(downloadFilesRequest, downloadFilesWorker);
	yield takeLatest(cloneMessageRequest, cloneMessageWorker);
}
