/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { ChangeEvent, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import { getFeeRequest } from 'redux/reducers/fee/reducer';
import {
	createUserRequest,
	createUserRequestFinished,
	setUserData,
} from 'redux/reducers/users/reducer';
import { notificationContainer } from 'services/utils/notificationContainer';
import { notificationsMessagesInfo } from 'services/utils/notificationsMessages/notificationsMessagesInfo';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import TextError from 'ui/Formik/TextError';
import * as yup from 'yup';
import { getActiveBankAccounts } from 'redux/reducers/bankAccounts/selectors';
import CountriesSelect from 'ui/Formik/Select/CountriesSelect';
import { notificationMessageUserAccount } from 'services/utils/notificationsMessages/notificationsMessageUserAccount';
import { notificationsInfoFields } from 'services/utils/inputFields/ipuntFields';
import BankAccountsWithSelect from 'components/tables/CreateUserTables/BankAccountsWithSelect';
import { getNameList } from 'country-list';
import Select from '../../../../ui/Formik/Select';
import { ItemSelect } from '../../../../ui/ItemSelect/ItemSelect';
import Input from '../../../../ui/Formik/Input';
import PhoneInputField from '../../../../ui/PhoneInputField';
import IconSvg from '../../../../ui/Svg/IconSvg';
import { capitalizeWords } from '../../../../services/utils/strings';

export interface ICountry {
	value: string;
	label: string;
}
const UserTableTab = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserCreateData);
	const activeBankAccounts = useSelector(getActiveBankAccounts);
	const createUserLoading = useSelector((state: any) => state.users.createUserLoader);

	const [counterStateCode, setStateCounterCode] = useState('US');
	const [phonePrefix, setPhonePrefix] = useState<string>(userData?.user_data?.phonePrefix || '1');

	const [countries, setCountries] = useState<ICountry[]>([]);
	const [selectedCompanyCountry, setSelectedCompanyCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	useLayoutEffect(() => {
		if (userData?.user_data?.company_country_registration !== undefined) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setSelectedCompanyCountry(userData.user_data.company_country_registration);
		}
	}, [userData?.user_data?.company_country_registration]);

	const [companyCountryError, setCompanyCountryError] = useState(false);

	const initialData = {
		email: userData?.user?.email || '',
		first_name: userData?.user_data?.first_name || '',
		last_name: userData?.user_data?.last_name || '',
		phone: userData?.user_data?.phone || '',
		position: userData?.user_data?.position || '',
		company_name: userData?.user_data?.company_name || '',
		company_country_registration: userData?.user_data?.company_country_registration || '',
		company_registration_number: userData?.user_data?.company_registration_number || '',
		company_website: userData?.user_data?.company_website || '',
		fireblocks_vault_id: userData?.user_data?.fireblocks_vault_id || '',
		oneify_id: userData?.user?.oneify_id || '',
	};
	const handleChangeAccType = (value: any) => {
		if (value === 'corporate' || value === 'individual') {
			dispatch(
				setUserData({
					...userData,
					user: { ...userData?.user, type: value === 'corporate' ? 'corporate' : 'individual' },
				}),
			);
		}
	};

	useLayoutEffect(() => {
		/* fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			}); */
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useLayoutEffect(() => {
		if (!userData?.user?.type) {
			dispatch(
				setUserData({
					...userData,
					user: { ...userData?.user, type: 'individual' },
				}),
			);
		}
	}, [dispatch, userData]);

	const handleCancelClick = () => {
		history.push('/user-management');
	};

	const validationSchemaIndividual = yup.object().shape({
		email: yup
			.string()
			.required(notificationMessageUserAccount.required('Email Address'))
			.email('Please enter a valid Email Address.')
			.max(65, notificationMessageUserAccount.max('Email Address', 65)),
		first_name: yup
			.string()
			.required(notificationMessageUserAccount.required('First Name'))
			.min(2, notificationMessageUserAccount.min('First Name', 2))
			.max(50, notificationMessageUserAccount.max('First Name', 50)),
		last_name: yup
			.string()
			.required(notificationMessageUserAccount.required('Last Name'))
			.min(2, notificationMessageUserAccount.min('Last Name', 2))
			.max(50, notificationMessageUserAccount.max('Last Name', 50)),
		// phone: yup
		// 	.string()
		// 	.required(notificationMessageUserAccount.required('Phone Number'))
		// 	.max(15, notificationMessageUserAccount.max('Phone Number', 15))
		// 	.matches(/^\d{7,15}$/, notificationMessageUserAccount.min('Phone Number', 7)),
		position: yup
			.string()
			.required(notificationMessageUserAccount.required('Position'))
			.min(2, notificationMessageUserAccount.min('Position', 2))
			.max(50, notificationMessageUserAccount.max('Position', 50)),
		company_name: yup
			.string()
			.required(notificationMessageUserAccount.required('Company Name'))
			.min(2, notificationMessageUserAccount.min('Company Name', 2))
			.max(100, notificationMessageUserAccount.max('Company Name', 100)),
		company_registration_number: yup
			.string()
			.required(notificationMessageUserAccount.required('Company Registration Number'))
			.min(2, notificationMessageUserAccount.min('Company Registration Number', 2))
			.max(30, notificationMessageUserAccount.max('Company Registration Number', 30)),
		// company_website: yup
		// 	.string()
		// 	.required(notificationMessageUserAccount.required('Website'))
		// 	.min(2, notificationMessageUserAccount.min('Website', 2))
		// 	.max(65, notificationMessageUserAccount.max('Website', 65)),

		fireblocks_vault_id: yup
			.string()
			.required(notificationMessageUserAccount.required('Fireblocks ID'))
			.max(19, notificationMessageUserAccount.max('Fireblocks ID', 19)),
	});

	const validationSchemaCorporate = yup.object().shape({
		email: yup
			.string()
			.required(notificationMessageUserAccount.required('Email Address'))
			.email('Please enter a valid Email Address.')
			.max(65, notificationMessageUserAccount.max('Email Address', 65)),
		first_name: yup
			.string()
			.required(notificationMessageUserAccount.required('First Name'))
			.min(2, notificationMessageUserAccount.min('First Name', 2))
			.max(50, notificationMessageUserAccount.max('First Name', 50)),

		last_name: yup
			.string()
			.required(notificationMessageUserAccount.required('Last Name'))
			.min(2, notificationMessageUserAccount.min('Last Name', 2))
			.max(50, notificationMessageUserAccount.max('Last Name', 50)),

		// phone: yup
		// 	.string()
		// 	.required(notificationMessageUserAccount.required('Phone Number'))
		// 	.max(15, notificationMessageUserAccount.max('Phone Number', 15))
		// 	.matches(/^\d{7,15}$/, notificationMessageUserAccount.min('Phone Number', 7)),
		fireblocks_vault_id: yup
			.string()
			.required(notificationMessageUserAccount.required('Fireblocks ID'))
			.max(19, notificationMessageUserAccount.max('Fireblocks ID', 19)),
	});
	const handleSubmitForm = (value: typeof initialData, resetForm: () => void) => {
		const requisites = activeBankAccounts?.map(({ bank_account_id }) => ({
			id: bank_account_id,
		}));

		const payloadCorporate = {
			apiPayload: {
				...userData,
				user: {
					...userData?.user,
					email: value.email,
				},
				user_data: {
					...userData?.user_data,
					phone: `${phonePrefix}${value.phone}`,
					company_country_registration: selectedCompanyCountry.label,
					liquidity_provider: 'b2c2',
					average_transaction_size: 'small',
					trading_frequency: 'frequent',
				},
				requisites,
				files: ['www', 'test'],
			},
			onSuccess: (userId: number) => {
				dispatch(setUserData(null));
				dispatch(createUserRequestFinished());
				resetForm();
				history.push('/user-management');
				notificationContainer(
					`New user with id: ${userId} was created successfully`,
					'success',
					'User created',
				);
			},
			history,
		};

		const payloadIndividual = {
			apiPayload: {
				...userData,
				user: {
					...userData?.user,
					email: value.email,
				},
				user_data: {
					...userData?.user_data,
					first_name: value.first_name,
					last_name: value.last_name,
					phone: `${phonePrefix}${value.phone}`,
					fireblocks_vault_id: value.fireblocks_vault_id,
					liquidity_provider: 'b2c2',
					average_transaction_size: 'small',
					trading_frequency: 'frequent',
				},
				requisites,
				files: ['www', 'test'],
				history,
			},

			onSuccess: (userId: number) => {
				dispatch(setUserData(null));
				dispatch(createUserRequestFinished());
				resetForm();
				// history.push(`/user-management/${userId}/account`);
				history.push('/user-management');
				notificationContainer(
					`New user with id: ${userId} was created successfully`,
					'success',
					'User created',
				);
			},
		};

		if (userData?.user?.type !== 'individual' && !selectedCompanyCountry.label) {
			setCompanyCountryError(true);
			return;
		}

		setCompanyCountryError(false);
		dispatch(
			createUserRequest(
				userData?.user?.type === 'individual' ? payloadIndividual : payloadCorporate,
			),
		);
	};

	const [touchedFields, setTouchedFields] = useState<{
		email?: string;
		first_name?: string;
		last_name?: string;
		phone?: string;
		position?: string;
		company_name?: string;
		company_country_registration?: string;
		company_registration_number?: string;
		company_website?: string;
		fireblocks_vault_id?: string | number;
		oneify_id?: string | number;
	}>({});

	const handleFieldBlur = (fieldName: string) => {
		setTouchedFields({ ...touchedFields, [fieldName]: true });
	};

	const handleSetAllFieldsTouched = () => {
		const allFields = Object.keys(initialData);
		const touchedAllFields = allFields.reduce((acc: { [key: string]: boolean }, field: string) => {
			acc[field] = true;
			return acc;
		}, {});

		setTouchedFields(touchedAllFields);
	};

	const validateCountryField = () => {
		let errors;
		if (
			userData?.user?.type !== 'individual' &&
			!selectedCompanyCountry.label &&
			touchedFields.company_country_registration
		) {
			setCompanyCountryError(true);
		}

		return errors;
	};

	const changeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.name === 'email') {
			dispatch(
				setUserData({
					...userData,
					user: {
						...userData?.user,
						[e.target.name]: e.target.value,
					},
				}),
			);
			return;
		}
		if (e.target.name === 'oneify_id') {
			dispatch(
				setUserData({
					...userData,
					user: {
						...userData?.user,
						[e.target.name]: e.target.value,
					},
				}),
			);
			return;
		}
		dispatch(
			setUserData({
				...userData,
				user_data: {
					...userData?.user_data,
					[e.target.name]: e.target.value,
				},
			}),
		);
	};

	const changePhoneValue = (value: any) => {
		dispatch(
			setUserData({
				...userData,
				user_data: {
					...userData?.user_data,
					phone: value,
				},
			}),
		);
	};

	const onPhoneChangeHandler = (value: string) => {
		dispatch(
			setUserData({
				...userData,
				user_data: {
					...userData?.user_data,
					phonePrefix: value,
				},
			}),
		);
		setPhonePrefix(value);
	};

	return (
		<Formik
			validateOnBlur
			enableReinitialize
			initialValues={initialData}
			validate={validateCountryField}
			validationSchema={
				userData?.user?.type !== 'individual'
					? validationSchemaIndividual
					: validationSchemaCorporate
			}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(false);
				handleSubmitForm(values, resetForm);
			}}
		>
			{({ handleChange, handleBlur, values, setFieldValue, errors }) => {
				return (
					<Form>
						<div className="user-management">
							<div
								className="user-management-body user-management-body--gap-40"
								// onChange={handleChangeData}
							>
								<div className="user-management-item">
									<div className="user-management-item__title user-management-item__title--type3">
										<p>Personal Information</p>
									</div>
									<div className="user-management-list user-management-list--gap-32">
										<div className="create-account-item">
											<div className="input">
												<p className="input__name">First Name</p>
												<div className="input-wrapper">
													<input
														className={`input-item ${
															errors.first_name && touchedFields?.first_name ? 'error-border' : ''
														}`}
														value={values.first_name}
														onChange={(e) => {
															handleChange(e);
															changeInputValue(e);
														}}
														onBlur={(e) => {
															handleBlur(e);
															handleFieldBlur('first_name');
														}}
														name="first_name"
														type="text"
														placeholder="Enter First Name"
													/>
													{errors.first_name && touchedFields?.first_name && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">{errors.first_name}</span>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className="input">
												<p className="input__name">Last Name</p>
												<div className="input-wrapper">
													<input
														className={`input-item ${
															errors.last_name && touchedFields?.last_name ? 'error-border' : ''
														}`}
														value={values.last_name}
														onChange={(e) => {
															handleChange(e);
															changeInputValue(e);
														}}
														onBlur={(e) => {
															handleBlur(e);
															handleFieldBlur('last_name');
														}}
														name="last_name"
														type="text"
														placeholder="Enter Last Name"
													/>
													{errors.last_name && touchedFields?.last_name && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">{errors.last_name}</span>
															</div>
														</div>
													)}
												</div>
											</div>

											<div className="input">
												<p className="input__name">Email Address</p>
												<div className="input-wrapper">
													<input
														className={`input-item ${
															errors.email && touchedFields.email ? 'error-border' : ''
														}`}
														value={values.email}
														onChange={(e) => {
															handleChange(e);
															changeInputValue(e);
														}}
														onBlur={(e) => {
															handleBlur(e);
															handleFieldBlur('email');
														}}
														name="email"
														type="text"
														placeholder="Enter Email Address"
													/>
													{errors.email && touchedFields?.email && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">{errors.email}</span>
															</div>
														</div>
													)}
												</div>
											</div>
											<div className="input">
												<p className="input__name">Phone Number</p>
												<div className="input-wrapper">
													<PhoneInputField
														counterStateCode={counterStateCode}
														// onChange={(e) => console.log(e)}
														// value={values.phone}
														onChange={onPhoneChangeHandler}
														value={phonePrefix}
														enableSearch
														dropdownClass="phone-dropdown"
														searchClass="searchfield"
													>
														<Field
															type="tel"
															placeholder="Phone Number"
															name="phone"
															required
															onBlur={(event: any) => {
																handleBlur(event);
																handleFieldBlur('phone');
															}}
															component={Input}
															className={`input-item ${
																errors.phone && touchedFields.phone ? 'error-border' : ''
															}`}
															onChange={(value: any) => {
																// handleChange(e);
																// changeInputValue(e);
																changePhoneValue(value);
																// console.log(e);
															}}
															onKeyUp={(e: any) => {
																e.preventDefault();
																const { value } = e.target;
																setFieldValue('phone', value.replace(/\D/g, ''));
															}}
															setValue={setFieldValue}
															errorMessage
														/>
													</PhoneInputField>
													{errors.phone && touchedFields?.phone && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">{errors.phone}</span>
															</div>
														</div>
													)}

													{/* <PhoneInput
														country="us"
														value={values.phone}
														onBlur={handleBlur}
														inputProps={{
															name: 'phone',
														}}
														onChange={(phoneNumber, country, e) => {
															handleChange(e);
															changeInputValue(e);
														}}
														placeholder="Enter phone number"
														inputClass={`input-item custom-phone-number-input  `}
													/> */}
													{/* <div className="error-red">
														<ErrorMessage name="phone" component={TextError} />
													</div> */}
												</div>
											</div>

											<div className="input">
												<p className="input__name">Account Type</p>
												<ItemSelect
													textInitial="Choose"
													changeFilter={(e: any) => {
														// setType(e.selectedItem);
														// setValues({ ...values, type: e.selectedItem });
														// changeInputValue(e.selectedItem);
														// console.log(e.selectedItem);
														handleChangeAccType(e.selectedItem);
													}}
													selectedItemsArray={[
														{
															id: 1,
															text: 'individual',
														},
														{
															id: 2,
															text: 'corporate',
														},
													]}
													selectedElement={userData?.user?.type}
												/>
											</div>

											{userData?.user?.type === 'individual' ? null : (
												<div className="input">
													<p className="input__name">Position</p>
													<div className={`input-wrapper `}>
														<input
															className={`input-item ${
																errors.position && touchedFields.position ? 'error-border' : ''
															}`}
															value={values.position}
															onChange={(e) => {
																handleChange(e);
																changeInputValue(e);
															}}
															onBlur={(e) => {
																handleBlur(e);
																handleFieldBlur('position');
															}}
															name="position"
															type="text"
															placeholder="Enter Position"
														/>
														{errors.position && touchedFields?.position && (
															<div className="error-red">
																<div className="input-notify">
																	<span className="input-notify__text">{errors.position}</span>
																</div>
															</div>
														)}
													</div>
												</div>
											)}

											<div className="input">
												<p className="input__name">User ID</p>
												<div className="input-wrapper">
													<input
														className="input-item"
														type="text"
														placeholder="User ID"
														disabled
													/>
												</div>
											</div>

											<div className="input">
												<p className="input__name">Oneify ID</p>
												<div className="input-wrapper">
													<input
														className={`input-item ${
															errors.oneify_id && touchedFields.email ? 'error-border' : ''
														}`}
														value={values.oneify_id}
														onChange={(e) => {
															handleChange(e);
															changeInputValue(e);
														}}
														onBlur={(e) => {
															handleBlur(e);
															handleFieldBlur('oneify_id');
														}}
														name="oneify_id"
														type="text"
														placeholder="Oneify ID"
													/>
													{errors.email && touchedFields?.email && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">{errors.email}</span>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
										{/* <div className="create-account-item create-account-item--type2">
											<div className="create-account-item__title">
												<p>Account Type</p>
											</div>
											<div className="radio">
												<label className="radio__label">
													<input
														type="radio"
														className="hidden"
														name="group6"
														value="individual"
														onChange={handleChangeAccType}
														checked={userData?.user?.type === 'individual'}
													/>
													<span className="radio__item" />
													<span className="radio__text">Individual</span>
												</label>
											</div>

											<div className="radio">
												<label className="radio__label">
													<input
														type="radio"
														className="hidden"
														name="group6"
														value="corporate"
														onChange={handleChangeAccType}
														checked={userData?.user?.type === 'corporate'}
													/>
													<span className="radio__item" />
													<span className="radio__text">Corporate</span>
												</label>
											</div>
										</div> */}
									</div>
								</div>

								{userData?.user?.type === 'individual' ? null : (
									<div className="user-management-item">
										<div className="user-management-item__title user-management-item__title--type3">
											<p>Corporate Information</p>
										</div>
										<div className="user-management-list user-management-list--gap-32">
											<div className="create-account-item">
												<div className="input">
													<p className="input__name">Company Name</p>
													<div className="input-wrapper">
														<input
															className={`input-item ${
																errors.company_name && touchedFields.company_name
																	? 'error-border'
																	: ''
															}`}
															value={values.company_name}
															onChange={(e) => {
																handleChange(e);
																changeInputValue(e);
															}}
															onBlur={(e) => {
																handleBlur(e);
																handleFieldBlur('company_name');
															}}
															type="text"
															name="company_name"
															placeholder="Enter Company Name"
														/>
														{errors.company_name && touchedFields?.company_name && (
															<div className="error-red">
																<div className="input-notify">
																	<span className="input-notify__text">{errors.company_name}</span>
																</div>
															</div>
														)}
													</div>
												</div>
												<div className="input">
													<p className="input__name">Company Registration Number</p>
													<div className="input-wrapper">
														<input
															className={`input-item ${
																errors.company_registration_number &&
																touchedFields.company_registration_number
																	? 'error-border'
																	: ''
															}`}
															type="text"
															name="company_registration_number"
															value={values.company_registration_number}
															onChange={(e) => {
																handleChange(e);
																changeInputValue(e);
															}}
															onBlur={(e) => {
																handleBlur(e);
																handleFieldBlur('company_registration_number');
															}}
															placeholder="Enter Company Registration Number"
														/>
														{errors.company_registration_number &&
															touchedFields?.company_registration_number && (
																<div className="error-red">
																	<div className="input-notify">
																		<span className="input-notify__text">
																			{errors.company_registration_number}
																		</span>
																	</div>
																</div>
															)}
													</div>
												</div>

												<div className="input">
													<div className="select-counter">
														<div className="select-block">
															<p className="select-block__name" style={{ fontWeight: '400' }}>
																Country of Registration
															</p>
															<div className="input-wrapper">
																{/* <input
																	className={`input-item `}
																	value={values.company_country_registration}
																	onChange={(e) => {
																		handleChange(e);
																		changeInputValue(e);
																	}}
																	onBlur={handleBlur}
																	type="text"
																	name="company_country_registration"
																	placeholder="Enter Country of Registration"
																/> */}
																<Field
																	type="text"
																	searchField
																	name="company_country_registration"
																	component={CountriesSelect}
																	arr={countries}
																	onChange={(e: any) => {
																		setSelectedCompanyCountry(e);
																		setCompanyCountryError(false);
																		handleFieldBlur('company_country_registration');
																		dispatch(
																			setUserData({
																				...userData,
																				user_data: {
																					...userData?.user_data,
																					company_country_registration: e,
																				},
																			}),
																		);
																		// setFieldValue('company_country_registration', e.label);
																		// handleChange(e.label);
																	}}
																	activeValue={selectedCompanyCountry || undefined}
																	className={companyCountryError ? 'error-border' : ''}
																/>
																<div className="error-red">
																	{/* <ErrorMessage
																		name="company_country_registration"
																		component={TextError}
																	/> */}
																	{companyCountryError && (
																		<div className="error-red">
																			<div className="input-notify">
																				<span className="input-notify__text">
																					Please enter Country of Registration.
																				</span>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="input website">
													<p className="input__name">Website</p>
													<div className="input-wrapper">
														<input
															className={`input-item ${
																errors.company_website && touchedFields.company_website
																	? 'error-border'
																	: ''
															}`}
															value={values.company_website}
															onChange={(e) => {
																handleChange(e);
																changeInputValue(e);
															}}
															onBlur={(e) => {
																handleBlur(e);
																handleFieldBlur('company_website');
															}}
															name="company_website"
															type="text"
															placeholder="Enter URL"
														/>
														<IconSvg name="link" />
													</div>
													{errors.company_website && touchedFields?.company_website && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">{errors.company_website}</span>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								)}

								<div className="user-management-item">
									<div className="user-management-item__title user-management-item__title--type3 user-management-item__title--bank-details-table">
										<p>Bank Details</p>
									</div>
									<BankAccountsWithSelect />
									<div className="user-management-item__title user-management-item__title--type3 mt-40">
										<p>Fireblocks User Vault ID</p>
									</div>
									<div className="user-management-list user-management-list--gap-32">
										<div className="create-account-item">
											<div className="input">
												<p className="input__name">Fireblocks</p>
												<div className="input-wrapper">
													<input
														className={`input-item ${
															errors.fireblocks_vault_id && touchedFields.fireblocks_vault_id
																? 'error-border'
																: ''
														}`}
														value={values.fireblocks_vault_id}
														onChange={(e) => {
															handleChange(e);
															changeInputValue(e);
														}}
														onBlur={(e) => {
															handleBlur(e);
															handleFieldBlur('fireblocks_vault_id');
														}}
														onWheel={(event) => event.currentTarget.blur()}
														type="number"
														name="fireblocks_vault_id"
														placeholder="Enter Fireblocks"
													/>
													{errors.fireblocks_vault_id && touchedFields?.fireblocks_vault_id && (
														<div className="error-red">
															<div className="input-notify">
																<span className="input-notify__text">
																	{errors.fireblocks_vault_id}
																</span>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
										<div className="create-account-item">
											<div className="button-wrap">
												<button
													type="button"
													onClick={handleCancelClick}
													className="btn btn-danger"
												>
													Cancel
												</button>

												<button
													type="submit"
													className={
														createUserLoading ? 'btn btn-primary is-loading' : 'btn btn-primary'
													}
													disabled={createUserLoading}
													onClick={handleSetAllFieldsTouched}
												>
													Create
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default UserTableTab;
