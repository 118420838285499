import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { roundingNumber } from 'services/utils/roundingNumber';
import { ETradeSide, ITrade } from 'services/api/transactions/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import IconSvg from 'ui/Svg/IconSvg';
import { useSelector } from 'react-redux';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';

const TradesTableRow: FC<ITrade> = ({
	created_at,
	user,
	pair,
	side,
	rate,
	quantity,
	amount,
	fee,
	liquidity_provider,
	liquidity_provider_id,
	ExternalUrl,
	from_asset_id,
	to_asset_id,
	id,
	price_b2c2,
	user_id,
}) => {
	const [date, time] = useFormatDateTime(created_at);
	const { path } = useRouteMatch();

	const pairArray = pair.split('_');
	const sell = side === ETradeSide.SELL ? pairArray[0] : pairArray[1];
	const buy = side === ETradeSide.SELL ? pairArray[1] : pairArray[0];
	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};

	const currency = useSelector(getCurrencyData);

	const handleSearchAssetName = (asset_id: number) => {
		const asset = currency.filter((item) => item.id === asset_id)[0];
		return asset?.name;
	};

	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}
	const [oneText, twoText] = splitText(pair || 'btc_eur');

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p className="td-name--flex">{user.id || ''}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sell</p>
				<p className="td-name--flex">{sell.toUpperCase()}</p>
				<span className="td-more-info"> {handleSearchAssetName(from_asset_id)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Buy</p>
				<p className="td-name--flex">{buy.toUpperCase()}</p>
				<span className="td-more-info"> {handleSearchAssetName(to_asset_id)}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sold</p>
				<p>{roundingNumber(quantity, sell)}</p>
				<span className="td-more-info">{sell.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Received</p>
				<p>{roundingNumber(amount, buy)}</p>
				<span className="td-more-info">{buy.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Exchange</p>
				{/* <p>{roundingNumber(price_b2c2, twoText)}</p> */}
				<p>{toFixedNumber(price_b2c2, 'crypto')}</p>
				<span className="td-more-info">{twoText.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{roundingNumber(fee, sell)}</p>
				<span className="td-more-info">{sell.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx ID</p>
				<Link
					to={{
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						pathname: `${path}/${id}`,
						state: { fromTradeReports: true, txId: id, userId: user_id },
					}}
				>
					{id}
				</Link>
			</div>
		</div>
	);
};

export default TradesTableRow;
