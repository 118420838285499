/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
	getFireblockIdLoading,
	getOneUser,
	getOneUserLoading,
	getUserLoader,
	getUserWallets,
} from 'redux/reducers/users/selectors';
import { AvatarDefault1Icon, CheckIcon, CloseIcon, PencilIcon, TrashIcon } from 'assets/inline-svg';
import { statusClassNames, statusNames } from 'components/AccountDetails/utiils';

import IconSvg from 'ui/Svg/IconSvg';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import TextError from 'ui/Formik/TextError';
import { ICountry } from 'components/tables/CreateUserTables/UserTable';
import { getFeeRequest } from 'redux/reducers/fee/reducer';
import {
	checkFireblockId,
	generateSecretForZip,
	getUserWalletsRequest,
	setUserStatus,
	updateUserDataRequest,
} from 'redux/reducers/users/reducer';
import StatusUpdatet from 'layouts-elements/PopUp/StatusUpdatet';
import { WEB_URL } from 'services/constants/env';
import { activateResetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import { getActiveBankAccounts } from 'redux/reducers/bankAccounts/selectors';
import BankAccountsWithSelect from 'components/tables/CreateUserTables/BankAccountsWithSelect';

import Loading from 'layouts-elements/Loading/Loading';
import Input from 'ui/Formik/Input';
import PhoneInputField from 'ui/PhoneInputField';
import { notificationMessageUserAccount } from 'services/utils/notificationsMessages/notificationsMessageUserAccount';
import { notificationsInfoFields } from 'services/utils/inputFields/ipuntFields';
import { CountryCode, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { getNameList } from 'country-list';
import EditFireblockItem from './EditFireblockItem';
import { selectedItemsArrayAccountType } from '../../../../redux/reducers/users/constants';
import { ItemSelect } from '../../../../ui/ItemSelect/ItemSelect';
import CountriesSelect from '../../../../ui/Formik/Select/CountriesSelect';
import { notificationContainer } from '../../../../services/utils/notificationContainer';
import AssociatedAccounts from '../../../tables/CreateUserTables/AssociatedAccounts';
import { capitalizeWords } from '../../../../services/utils/strings';

const AccountTabView = () => {
	const dispatch = useDispatch();
	const user = useSelector(getOneUser);
	const wallet = useSelector(getUserWallets);
	const loading = useSelector(getUserLoader);
	useLayoutEffect(() => {
		if (user?.id !== undefined) {
			dispatch(getUserWalletsRequest({ user_id: String(user?.id) }));
		}
	}, [dispatch, user]);
	const [counterStateCode, setStateCounterCode] = useState('US');

	const [phonePrefix, setPhonePrefix] = useState<string>('+1');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [fullName, setFullName] = useState<string | null>('');

	const onChangePhone = (value: string, counterCode: string) => {
		if (user?.data?.phone && user?.data?.phone !== '1' && user?.data?.phone !== '+1') {
			const fullPhone = String(user?.data?.phone).replace('+', '');
			const phoneNumberValue = parsePhoneNumber(`+${fullPhone}`);
			const country = phoneNumberValue?.country || 'US';
			const countryCallingCode = getCountryCallingCode(country);
			setPhoneNumber(fullPhone.replace(countryCallingCode, ''));
		}
		setPhonePrefix(value);
		setStateCounterCode(counterCode);
	};
	useLayoutEffect(() => {
		if (user?.data?.phone && user?.data?.phone !== '1' && user?.data?.phone !== '+1') {
			const fullPhone = String(user?.data?.phone).replace('+', '');
			const phoneNumberValue = parsePhoneNumber(`+${fullPhone}`);
			const country = phoneNumberValue?.country || 'US';
			const countryCallingCode = getCountryCallingCode(country);
			setPhonePrefix(countryCallingCode);
			setPhoneNumber(fullPhone.replace(countryCallingCode, '').replace(/\D/g, ''));
			setStateCounterCode(country);
			return;
		}
		setPhonePrefix('+1');
		setPhoneNumber('');
		setStateCounterCode('US');
		// console.log(user?.data);
	}, [user?.data]);

	const userLoading = useSelector(getOneUserLoading);
	const activeBankAccounts = useSelector(getActiveBankAccounts);
	const [showDetails, setShowDetails] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editFireblock, setEditFireblock] = useState(false);

	const [verified, setVerified] = useState(false);
	const [rejected, setRejected] = useState(false);

	const permissions = useSelector(getPermissions);
	const [isCountryOpen, setIsCountryOpen] = useState<boolean>(false);
	const [countries, setCountries] = useState<ICountry[]>([]);
	const dropdownCountryRef = useRef(null);
	const selectCountryRef = useRef(null);
	const [isCurrencyOpen, setIsCurrencyOpen] = useState<boolean>(false);
	const dropdownCurrencyRef = useRef(null);
	const selectCurrencyRef = useRef(null);
	const [type, setType] = useState(user?.type);
	// const [fireblockId, setFireblockId] = useState('');
	const fireblockIdLoading = useSelector(getFireblockIdLoading);
	useLayoutEffect(() => {
		setEditFireblock(false);
	}, [fireblockIdLoading]);

	const [counterPhoneStateCode, setCounterPhoneStateCode] = useState('us');

	const [countryFlag, setCountryFlag] = useState<any>(null);
	const [countryName, setCountryName] = useState<any>(null);
	const [companyCountryError, setCompanyCountryError] = useState(false);

	const [selectedCompanyCountry, setSelectedCompanyCountry] = useState<ICountry>({
		value: '',
		label: '',
	});

	useLayoutEffect(() => {
		setSelectedCompanyCountry({
			value: countryFlag,
			label: countryName,
		});
	}, [countryFlag, countryName]);

	useLayoutEffect(() => {
		if (user?.type === 'corporate' && user?.data?.company_country_registration) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			let userCountry = user?.data?.company_country_registration.replace(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
				'',
			);
			if (userCountry.startsWith(' ')) {
				userCountry = userCountry.substring(1);
			}

			const fileteredCountry = countries.filter((item) => item.label.includes(userCountry));
			if (fileteredCountry) {
				setCountryFlag(fileteredCountry[0]?.value);
				setCountryName(userCountry);
			}
		}
	}, [countries, user]);

	useLayoutEffect(() => {
		setType(user?.type);
		if (user && user.status_id === 2) {
			setVerified(true);
			setRejected(false);
		}
		if (user && user.status_id === 4) {
			setVerified(false);
			setRejected(true);
		}
		if (user && user.status_id === 1) {
			setVerified(false);
			setRejected(false);
		}
	}, [user]);

	useLayoutEffect(() => {
		const data = {
			per_page: 100,
			current_page: 1,
			type: 'fiat',
		};
		dispatch(getFeeRequest(data));
		dispatch(activateResetActiveBankAccounts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		/* fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			}); */
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	const handleCountrySelect = () => {
		setIsCountryOpen(!isCountryOpen);
	};

	const handlerDowload = () => {
		if (user) {
			dispatch(generateSecretForZip(user.id));
		}

		// user?.documents.forEach((el) => {
		// 	const attachment = `${String(WEB_URL?.replace('admin.', '') || '')}/storage/${el.path}`;
		// 	const link = document.createElement('a');
		// 	link.href = attachment;
		// 	link.target = '_blank';
		// 	link.download = attachment;
		// 	document.body.appendChild(link);
		// 	link.click();
		// 	link.remove();
		// });
	};

	const [openStatusUpdatet, setOpenStatusUpdatet] = useState(false);
	const [resetStates, setResetStates] = useState(false);
	const [eventStatus, setEventStatus] = useState('');

	const closeStatusUpdatet = useCallback(() => {
		setOpenStatusUpdatet(false);
	}, []);

	const handleStatusUpdatet = useCallback((event: string) => {
		setOpenStatusUpdatet(true);
		setEventStatus(event);
	}, []);

	const onWindowClick = useCallback(
		(e: Event) => {
			const path = e.composedPath();
			if (dropdownCountryRef?.current && selectCountryRef?.current) {
				if (
					!path.includes(dropdownCountryRef?.current) &&
					!path.includes(selectCountryRef?.current) &&
					isCountryOpen
				) {
					setIsCountryOpen(false);
				}
			}
			if (dropdownCurrencyRef?.current && selectCurrencyRef?.current) {
				if (
					!path.includes(dropdownCurrencyRef?.current) &&
					!path.includes(selectCurrencyRef?.current) &&
					isCurrencyOpen
				) {
					setIsCurrencyOpen(false);
				}
			}
		},
		[isCurrencyOpen, isCountryOpen],
	);

	const handleEditFireblockId = (fireblockId: any) => {
		if (user) {
			// dispatch(checkFireblockId({ userId: user?.id, fireblockId }));
			dispatch(checkFireblockId({ userId: user?.id, fireblockId }));
			// console.log(fireblockId);
		}
	};
	const [touchedFields, setTouchedFields] = useState<{
		oneify_id?: string;
		email?: string;
		first_name?: string;
		last_name?: string;
		phone?: string;
		position?: string;
		company_name?: string;
		company_country_registration?: string;
		registration_number?: string;
		website?: string;
		fireblocks_vault_id?: string | number;
	}>({});

	useLayoutEffect(() => {
		window.addEventListener('click', onWindowClick, true);
		return () => window.removeEventListener('click', onWindowClick);
	}, [onWindowClick]);

	const handlerShowDetails = useCallback(() => {
		setShowDetails(!showDetails);
	}, [showDetails]);

	if (userLoading || !user) {
		return null;
	}

	const handleEditClick = () => {
		setResetStates(!resetStates);
		setEditMode(!editMode);
	};

	const handleEditFireblock = () => {
		setEditFireblock(!editFireblock);
	};

	const handleVerifiedChange = () => {
		let status;
		if (verified) {
			status = 1;
		} else status = 2;
		dispatch(setUserStatus({ id: user.id, status }));
	};

	const handleRejectedChange = () => {
		let status;
		if (rejected) {
			status = 1;
		} else status = 4;
		dispatch(setUserStatus({ id: user.id, status }));
	};

	const initData = {
		email: user.email || '',
		type: user.type || '',
		first_name: user.data.first_name || '',
		last_name: user.data.last_name || '',
		phone: phoneNumber,
		position: user.data.position || '',
		company_name: user.data.company_name || '',
		company_country_registration: user.data.company_country_registration || '',
		registration_number: user.data.company_registration_number || '',
		website: user.data.company_website || '',
		fireblocks_vault_id: user.data.fireblocks_vault_id || '',
		user_id: user.data.user_id,
		oneify_id: user.oneify_id || '',
		// Bank deteils
		// bank_nickname: user?.data?.bank_nickname || '',
		// bank_beneficiary_name: user?.data?.bank_beneficiary_name || '',
		// bank_account_number: user?.data?.bank_account_number || '',
		// bank_beneficiary_address: user?.data?.bank_beneficiary_address || '',
		// bank_swift_bic: user?.data?.bank_swift_bic || '',
		// bank_address: user?.data?.bank_address || '',
		// bank_currency: user?.data?.bank_currency || '',
	};
	const validationSchemaIndividual = yup.object().shape({
		email: yup
			.string()
			.required(notificationMessageUserAccount.required('Email Address'))
			.email('Please enter a valid Email Address.')
			.max(65, notificationMessageUserAccount.max('Email Address', 65)),
		type: yup.string().required(),
		first_name: yup
			.string()
			.required(notificationMessageUserAccount.required('First Name'))
			.min(2, notificationMessageUserAccount.min('First Name', 2))
			.max(50, notificationMessageUserAccount.max('First Name', 50)),
		last_name: yup
			.string()
			.required(notificationMessageUserAccount.required('Last Name'))
			.min(2, notificationMessageUserAccount.min('Last Name', 2))
			.max(50, notificationMessageUserAccount.max('Last Name', 50)),
		// phone: yup
		// 	.string()
		// 	.required(notificationMessageUserAccount.required('Phone Number'))
		// 	.max(15, notificationMessageUserAccount.max('Phone Number', 15))
		// 	.matches(/^\d{7,15}$/, notificationMessageUserAccount.min('Phone Number', 7)),
		fireblocks_vault_id: yup
			.string()
			.required(notificationMessageUserAccount.required('Fireblocks ID'))
			.max(19, notificationMessageUserAccount.max('Fireblocks ID', 19)),
	});
	const validationSchemaCorporate = yup.object().shape({
		email: yup
			.string()
			.required(notificationMessageUserAccount.required('Email Address'))
			.email('Please enter a valid Email Address.')
			.max(65, notificationMessageUserAccount.max('Email Address', 65)),
		type: yup.string().required(),
		first_name: yup
			.string()
			.required(notificationMessageUserAccount.required('First Name'))
			.min(2, notificationMessageUserAccount.min('First Name', 2))
			.max(50, notificationMessageUserAccount.max('First Name', 50)),
		last_name: yup
			.string()
			.required(notificationMessageUserAccount.required('Last Name'))
			.min(2, notificationMessageUserAccount.min('Last Name', 2))
			.max(50, notificationMessageUserAccount.max('Last Name', 50)),
		// phone: yup
		// 	.string()
		// 	.required(notificationMessageUserAccount.required('Phone Number'))
		// 	.max(15, notificationMessageUserAccount.max('Phone Number', 15))
		// 	.matches(/^\d{7,15}$/, notificationMessageUserAccount.min('Phone Number', 7)),
		position: yup
			.string()
			.required(notificationMessageUserAccount.required('Position'))
			.min(2, notificationMessageUserAccount.min('Position', 2))
			.max(50, notificationMessageUserAccount.max('Position', 50)),
		company_name: yup
			.string()
			.required(notificationMessageUserAccount.required('Company Name'))
			.min(2, notificationMessageUserAccount.min('Company Name', 2))
			.max(100, notificationMessageUserAccount.max('Company Name', 100)),
		company_country_registration: yup.string().required('Please enter registration country'),
		registration_number: yup
			.string()
			.required(notificationMessageUserAccount.required('Company Registration Number'))
			.min(2, notificationMessageUserAccount.min('Company Registration Number', 2))
			.max(30, notificationMessageUserAccount.max('Company Registration Number', 30)),
		// website: yup
		// 	.string()
		// 	.required(notificationMessageUserAccount.required('Website'))
		// 	.min(2, notificationMessageUserAccount.min('Website', 2))
		// 	.max(65, notificationMessageUserAccount.max('Website', 65)),
		fireblocks_vault_id: yup
			.string()
			.required(notificationMessageUserAccount.required('Fireblocks ID'))
			.max(19, notificationMessageUserAccount.max('Fireblocks ID', 19)),
		// Bank deteils
		// bank_nickname: yup.string().required('Please enter bank nickname'),
		// bank_beneficiary_name: yup.string().required('Please enter beneficiary name'),
		// bank_account_number: yup.string().required('Please enter account number'),
		// bank_beneficiary_address: yup.string().required('Please enter beneficiary address'),
		// bank_swift_bic: yup.string().required('Please enter SWIFT/BIC code'),
		// bank_address: yup.string().required('Please enter bank address'),
		// bank_currency: yup.string().required('Please enter currency type'),
	});

	const handleSubmitForm = (values: typeof initData) => {
		const requisites = activeBankAccounts?.map(({ bank_account_id }) => ({
			id: bank_account_id,
		}));
		const payloadPersonal = {
			id: user.id,
			settings: {
				user: {
					email: values.email || user.email,
					type: values.type,
					oneify_id: values.oneify_id,
				},
				user_data: {
					account_type: values.type || user.data.account_type,
					legacy_id: user.id,
					first_name: values.first_name || user.data.first_name,
					last_name: values.last_name || user.data.last_name,
					phone: `${phonePrefix + values.phone}` || user.data.phone,
					// bank details
					// bank_nickname: values.bank_nickname || user.data.bank_nickname,
					// bank_beneficiary_name: values.bank_beneficiary_name || user.data.bank_beneficiary_name,
					// bank_account_number: values.bank_account_number || user.data.bank_account_number,
					// bank_beneficiary_address:
					// 	values.bank_beneficiary_address || user.data.bank_beneficiary_address,
					// bank_swift_bic: values.bank_swift_bic || user.data.bank_swift_bic,
					// bank_address: values.bank_address || user.data.bank_address,
					// bank_currency: values.bank_nickname || user.data.bank_currency,
					// fireblocks_vault_id: values.fireblocks_vault_id || user.data.fireblocks_vault_id,
				},
				requisites,
			},
		};
		const payloadCorporate = {
			id: user.id,
			settings: {
				user: {
					email: values.email || user.email,
					type: values.type,
					oneify_id: values.oneify_id,
				},
				user_data: {
					account_type: values.type || user.data.account_type,
					legacy_id: user.id,
					position: values.position || user.data.position,
					first_name: values.first_name || user.data.first_name,
					last_name: values.last_name || user.data.last_name,
					phone: `${phonePrefix + values.phone}` || user.data.phone,
					// fireblocks_vault_id: values.fireblocks_vault_id || user.data.fireblocks_vault_id,
					company_name: values.company_name || user.data.company_name,
					company_registration_number:
						values.registration_number || user.data.company_registration_number,
					company_country_registration:
						values.company_country_registration || user.data.company_country_registration,
					company_website: values.website || user.data.company_website,
				},
				requisites,
			},
		};

		dispatch(updateUserDataRequest(type === 'individual' ? payloadPersonal : payloadCorporate));
		setResetStates(!resetStates);
		setEditMode(false);
	};
	const resetFullFunc = () => {
		setType(user?.type);

		if (user?.data?.phone && user?.data?.phone !== '1' && user?.data?.phone !== '+1') {
			const fullPhone = String(user?.data?.phone).replace('+', '');
			const phoneNumberValue = parsePhoneNumber(`+${fullPhone}`);
			const country = phoneNumberValue?.country || 'US';
			const countryCallingCode = getCountryCallingCode(country);
			setPhonePrefix(countryCallingCode);
			setStateCounterCode(countryCallingCode);

			setPhoneNumber(fullPhone.replace(countryCallingCode, '').replace(/\D/g, ''));
		}

		if (user?.type === 'corporate' && user?.data?.company_country_registration) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			let userCountry = user?.data?.company_country_registration.replace(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
				'',
			);
			if (userCountry.startsWith(' ')) {
				userCountry = userCountry.substring(1);
			}
			const fileteredCountry = countries.filter((item) => item.label.includes(userCountry));
			if (fileteredCountry) {
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				setCountryFlag(fileteredCountry[0]?.value);
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				setCountryName(userCountry);

				setSelectedCompanyCountry({
					value: fileteredCountry[0]?.value,
					label: userCountry,
				});
			}
		} else {
			setSelectedCompanyCountry({
				value: '',
				label: '',
			});
		}
	};

	const validateCountryField = () => {
		let errors;
		if (
			type !== 'individual' &&
			!selectedCompanyCountry.label &&
			touchedFields.company_country_registration
		) {
			setCompanyCountryError(true);
		}

		return errors;
	};

	const handleFieldBlur = (fieldName: string) => {
		setTouchedFields({ ...touchedFields, [fieldName]: true });
	};

	const handleSetAllFieldsTouched = () => {
		const allFields = Object.keys(initData);
		const touchedAllFields = allFields.reduce((acc: { [key: string]: boolean }, field: string) => {
			acc[field] = true;
			return acc;
		}, {});

		setTouchedFields(touchedAllFields);
	};

	return (
		<>
			{loading ? (
				<Loading height={600} />
			) : (
				<>
					<StatusUpdatet
						open={openStatusUpdatet}
						closeModal={closeStatusUpdatet}
						event={eventStatus}
						userId={user.id}
						query={{}}
					/>
					<Formik
						validateOnBlur
						enableReinitialize
						validate={validateCountryField}
						initialValues={initData}
						validationSchema={
							type === 'individual' ? validationSchemaIndividual : validationSchemaCorporate
						}
						onSubmit={(values, { resetForm, setSubmitting }) => {
							setSubmitting(false);
							handleSubmitForm(values);
							resetForm();
						}}
					>
						{({ handleChange, handleBlur, values, setValues, errors, resetForm }) => {
							return (
								<Form>
									<div className="account">
										<div className="account-header">
											<div className="account-header__actions">
												{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && !editMode && (
													<>
														<button
															type="button"
															className="btn btn--icon btn--edit"
															onClick={handleEditClick}
														/>

														<button
															type="button"
															className="btn btn--icon-danger btn--delete"
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-ignore
															disabled={user?.is_archived === 1}
															onClick={() => {
																if (wallet !== null) {
																	if (
																		wallet.crypto.btc +
																			wallet.crypto.eur +
																			wallet.fiat.btc +
																			wallet.fiat.eur <=
																		0
																	) {
																		handleStatusUpdatet('archive');
																	} else {
																		notificationContainer(
																			'User still has funds on his balance. If archived, access to the funds will be lost.<br>In order to archive a user, his platform balance should be 0',
																			'error_archiving',
																			'User archivation warning',
																		);
																	}
																}
															}}
														/>
													</>
												)}

												{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && editMode && (
													<>
														<button
															type="button"
															className="btn--icon-danger btn--cancel"
															onClick={() => {
																handleEditClick();
																resetForm();
																resetFullFunc();
															}}
														/>
														<button
															type="submit"
															className="btn--icon-success btn--check"
															onClick={handleSetAllFieldsTouched}
														/>
													</>
												)}
											</div>
										</div>
										<div className="account-body">
											<>
												<div className="account-item">
													<div className="account-item__title">
														<p>Personal Information</p>
													</div>
													<div className="account-list">
														<div className="account-list-item">
															<div className="account-list-item__name">
																<p>First Name</p>
															</div>
															<div className="account-list-item__info">
																<div className="input">
																	<div className="input-wrapper">
																		<input
																			disabled={!editMode}
																			name="first_name"
																			value={values.first_name}
																			onChange={handleChange}
																			onBlur={(e) => {
																				handleBlur(e);
																				handleFieldBlur('first_name');
																			}}
																			className={`input-item input-item--user-data ${
																				errors.first_name && touchedFields?.first_name
																					? 'error-border'
																					: ''
																			}`}
																			type="text"
																			placeholder="Enter First Name"
																		/>
																	</div>
																</div>
																{errors.first_name && touchedFields?.first_name && (
																	<div className="error-red">
																		<div className="input-notify">
																			<span className="input-notify__text">
																				{errors.first_name}
																			</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className="account-list-item">
															<div className="account-list-item__name">
																<p>Last Name</p>
															</div>
															<div className="account-list-item__info">
																<div className="input">
																	<div className="input-wrapper">
																		<input
																			disabled={!editMode}
																			name="last_name"
																			value={values.last_name}
																			onChange={handleChange}
																			onBlur={(e) => {
																				handleBlur(e);
																				handleFieldBlur('last_name');
																			}}
																			className={`input-item input-item--user-data ${
																				errors.last_name && touchedFields?.last_name
																					? 'error-border'
																					: ''
																			}`}
																			type="text"
																			placeholder="Enter Last Name"
																		/>
																	</div>
																</div>
																{errors.last_name && touchedFields?.last_name && (
																	<div className="error-red">
																		<div className="input-notify">
																			<span className="input-notify__text">{errors.last_name}</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className="account-list-item">
															<div className="account-list-item__name">
																<p>Email Address</p>
															</div>
															<div className="account-list-item__info">
																<div className="input">
																	<div className="input-wrapper">
																		<input
																			disabled={!editMode}
																			name="email"
																			value={values.email}
																			onChange={handleChange}
																			onBlur={(e) => {
																				handleBlur(e);
																				handleFieldBlur('email');
																			}}
																			className={`input-item input-item--user-data ${
																				errors.email && touchedFields?.email ? 'error-border' : ''
																			}`}
																			type="text"
																			placeholder="Enter Email"
																		/>
																	</div>
																</div>
																{errors.email && touchedFields?.email && (
																	<div className="error-red">
																		<div className="input-notify">
																			<span className="input-notify__text">{errors.email}</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className="account-list-item">
															<div className="account-list-item__name">
																<p>Phone Number</p>
															</div>
															<div className="account-list-item__info custom-phone-number-input-wrapper">
																<div className="input input-item--user-data">
																	<div className="input-wrapper">
																		<PhoneInputField
																			isDisabled={!editMode}
																			counterStateCode={counterStateCode}
																			onChange={onChangePhone}
																			value={phonePrefix}
																			enableSearch
																			dropdownClass="phone-dropdown"
																			searchClass="searchfield"
																		>
																			<Field
																				disable={!editMode}
																				type="tel"
																				placeholder="Phone Number"
																				name="phone"
																				required
																				component={Input}
																				setValue={setValues}
																				onBlur={(event: any) => {
																					handleBlur(event);
																					handleFieldBlur('phone');
																				}}
																				errorMessage
																				className={`input-item ${
																					errors.phone && touchedFields.phone ? 'error-border' : ''
																				}`}
																			/>
																		</PhoneInputField>
																	</div>
																</div>

																{errors.phone && touchedFields?.phone && (
																	<div className="error-red">
																		<div className="input-notify">
																			<span className="input-notify__text">{errors.phone}</span>
																		</div>
																	</div>
																)}
															</div>
														</div>
														<div className="account-list-item account-list-item--type2">
															<div className="account-list-item__name">
																<p>Account Type</p>
															</div>
															<div className="account-list-item__info">
																<ItemSelect
																	disabled={!editMode}
																	textInitial="Choose"
																	changeFilter={(e: any) => {
																		setType(e.selectedItem);
																		setValues({ ...values, type: e.selectedItem });
																	}}
																	selectedItemsArray={selectedItemsArrayAccountType}
																	selectedElement={type}
																/>
															</div>
														</div>
														{type === 'corporate' && (
															<div className="account-list-item">
																<div className="account-list-item__name">
																	<p>Position</p>
																</div>
																<div className="account-list-item__info">
																	<div className="input">
																		<div className="input-wrapper">
																			<input
																				disabled={!editMode}
																				name="position"
																				value={values.position}
																				onChange={handleChange}
																				onBlur={(e) => {
																					handleBlur(e);
																					handleFieldBlur('position');
																				}}
																				className={`input-item input-item--user-data ${
																					errors.position && touchedFields.position
																						? 'error-border'
																						: ''
																				}`}
																				type="text"
																				placeholder="Enter Position"
																			/>
																		</div>
																	</div>
																	{errors.position && touchedFields?.position && (
																		<div className="error-red">
																			<div className="input-notify">
																				<span className="input-notify__text">
																					{errors.position}
																				</span>
																			</div>
																		</div>
																	)}
																</div>
															</div>
														)}
														<div className="account-list-item">
															<div className="account-list-item__name">
																<p>User ID</p>
															</div>
															<div className="account-list-item__info">
																<div className="input">
																	<div className="input-wrapper">
																		<input
																			disabled
																			name="user_id"
																			value={user.id}
																			className={`input-item input-item--user-data ${
																				errors.user_id ? 'error-border' : ''
																			}`}
																			type="text"
																		/>
																	</div>
																</div>
																<div className="error-red">
																	<ErrorMessage name="user_id" />
																</div>
															</div>
														</div>
														<div className="account-list-item">
															<div className="account-list-item__name">
																<p>Oneify ID</p>
															</div>
															<div className="account-list-item__info">
																<div className="input">
																	<div className="input-wrapper">
																		<input
																			disabled={!editMode}
																			name="oneify_id"
																			value={values.oneify_id}
																			onChange={handleChange}
																			onBlur={(e) => {
																				handleBlur(e);
																				handleFieldBlur('oneify_id');
																			}}
																			className={`input-item input-item--user-data ${
																				errors.oneify_id && touchedFields?.oneify_id
																					? 'error-border'
																					: ''
																			}`}
																			type="text"
																			placeholder="Enter Oneify ID"
																		/>
																	</div>
																</div>
																<div className="error-red">
																	<ErrorMessage name="oneify_id" />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="account-item">
													{values.type === 'individual' ? null : (
														<>
															<div className="account-item__title">
																<p>Corporate Information</p>
															</div>
															<div className="account-list">
																<div className="account-list-item">
																	<div className="account-list-item__name">
																		<p>Company Name</p>
																	</div>
																	<div className="account-list-item__info">
																		<div className="input">
																			<div className="input-wrapper">
																				<input
																					disabled={!editMode}
																					name="company_name"
																					value={values.company_name}
																					onChange={handleChange}
																					onBlur={(e) => {
																						handleBlur(e);
																						handleFieldBlur('company_name');
																					}}
																					className={`input-item input-item--user-data ${
																						errors.company_name && touchedFields.company_name
																							? 'error-border'
																							: ''
																					}`}
																					type="text"
																					placeholder="Enter Company Name"
																				/>
																			</div>
																		</div>
																		{errors.company_name && touchedFields?.company_name && (
																			<div className="error-red">
																				<div className="input-notify">
																					<span className="input-notify__text">
																						{errors.company_name}
																					</span>
																				</div>
																			</div>
																		)}
																	</div>
																</div>
																<div className="account-list-item">
																	<div className="account-list-item__name">
																		<p>Company Registration Number</p>
																	</div>
																	<div className="account-list-item__info">
																		<div className="input">
																			<div className="input-wrapper">
																				<input
																					disabled={!editMode}
																					name="registration_number"
																					value={values.registration_number}
																					onChange={handleChange}
																					onBlur={(e) => {
																						handleBlur(e);
																						handleFieldBlur('registration_number');
																					}}
																					className={`input-item input-item--user-data ${
																						errors.registration_number &&
																						touchedFields.registration_number
																							? 'error-border'
																							: ''
																					}`}
																					type="text"
																					placeholder="Enter Company Registration Number"
																				/>
																			</div>
																		</div>
																		{errors.registration_number &&
																			touchedFields?.registration_number && (
																				<div className="error-red">
																					<div className="input-notify">
																						<span className="input-notify__text">
																							{errors.registration_number}
																						</span>
																					</div>
																				</div>
																			)}
																	</div>
																</div>
																<div className="account-list-item">
																	<div className="account-list-item__name">
																		<p>Country of Registration</p>
																	</div>
																	<div className="account-list-item__info">
																		<div
																			className={`select-counter ${!editMode ? 'disabled' : ''} `}
																		>
																			<Field
																				disabled={!editMode}
																				type="text"
																				searchField
																				name="company_country_registration"
																				component={CountriesSelect}
																				arr={countries}
																				onChange={(e: any) => {
																					setSelectedCompanyCountry(e);
																					setCompanyCountryError(false);
																					handleFieldBlur('company_country_registration');
																				}}
																				activeValue={selectedCompanyCountry || undefined}
																				className={companyCountryError ? 'error-border' : ''}
																			/>
																		</div>
																	</div>
																	<div className="error-wrapper">
																		{companyCountryError && (
																			<div className="error-red">
																				<div className="input-notify">
																					<span className="input-notify__text">
																						Please enter Country of Registration.
																					</span>
																				</div>
																			</div>
																		)}
																	</div>
																</div>
																<div className="account-list-item">
																	<div className="account-list-item__name">
																		<p>Website</p>
																	</div>
																	<div className="account-list-item__info website">
																		<div className="input">
																			<div className="input-wrapper">
																				<input
																					disabled={!editMode}
																					name="website"
																					value={values.website}
																					onChange={handleChange}
																					onBlur={(e) => {
																						handleBlur(e);
																						handleFieldBlur('website');
																					}}
																					className={`input-item input-item--user-data ${
																						errors.website && touchedFields.website
																							? 'error-border'
																							: ''
																					}`}
																					type="text"
																					placeholder="Enter Website"
																				/>
																				<IconSvg name="link" />
																			</div>
																		</div>

																		{errors.website && touchedFields?.website && (
																			<div className="error-red">
																				<div className="input-notify">
																					<span className="input-notify__text">
																						{errors.website}
																					</span>
																				</div>
																			</div>
																		)}
																	</div>
																</div>
															</div>
														</>
													)}
												</div>
												<div className="user-management-item__title user-management-item__title--type3 user-management-item__title--bank-details-table">
													<p>Bank Details</p>
												</div>
												<BankAccountsWithSelect
													userId={user.id}
													resetStates={resetStates}
													editMode={editMode}
												/>
												{/* <div */}
												{/*	style={{ marginTop: '15px' }} */}
												{/*	className="user-management-item__title user-management-item__title--type3 user-management-item__title--bank-details-table" */}
												{/* > */}
												{/*	<p>Associated Accounts</p> */}
												{/* </div> */}
												{/* <AssociatedAccounts */}
												{/*	userId={user.id} */}
												{/*	resetStates={resetStates} */}
												{/*	editMode={editMode} */}
												{/* /> */}
												{/* </div> */}
											</>
											<div className="account-item">
												<div className="account-item__title">
													<p>Fireblocks User Vault ID</p>
													<div className="table-buttons">
														<div className="account-item__actions">
															{!editFireblock &&
															!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable ? (
																<button
																	disabled={fireblockIdLoading}
																	type="button"
																	className="btn btn--icon btn--edit"
																	onClick={handleEditFireblock}
																/>
															) : (
																<>
																	<button
																		type="button"
																		className="btn btn--icon btn--cancel"
																		onClick={() => {
																			handleEditFireblock();
																			resetForm();
																		}}
																	/>

																	<button
																		type="button"
																		onClick={() => {
																			handleEditFireblockId(values.fireblocks_vault_id);
																			resetForm();
																		}}
																		className="btn btn--icon btn--check"
																		disabled={!!errors.fireblocks_vault_id}
																	/>
																</>
															)}
														</div>
													</div>
												</div>

												<div className="account-list">
													<div className="account-list-item">
														<div className="account-list-item__name">
															<p>Fireblocks</p>
														</div>
														{!fireblockIdLoading ? (
															<>
																<div className="input">
																	<div className="input-wrapper">
																		<input
																			disabled={!editFireblock}
																			name="fireblocks_vault_id"
																			value={values.fireblocks_vault_id}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			className={`input-item input-item--user-data ${
																				errors.fireblocks_vault_id ? 'error-border' : ''
																			}`}
																			type="text"
																			placeholder="Enter Fireblocks"
																		/>
																	</div>
																</div>
																<div className="error-red">
																	<ErrorMessage name="fireblocks_vault_id" />
																</div>
															</>
														) : (
															<span>
																<Loading />
															</span>
														)}

														{/* {!editFireblock ? ( */}
														{/*	<div className="account-list-item__info"> */}
														{/*		<p>{user.data.fireblocks_vault_id}</p> */}
														{/*	</div> */}
														{/* ) : ( */}
														{/*	<Field */}
														{/*		name="fireblocks_vault_id" */}
														{/*		component={EditFireblockItem} */}
														{/*		setEdit={setFireblockId} */}
														{/*		placeholder="Enter Fireblocks" */}
														{/*	/> */}
														{/* )} */}
													</div>
												</div>
											</div>
											<div className="account-item">
												<div className="account-list_100">
													<div className="account-item__title account-item__title--mb-0">
														<p>Verification</p>

														<span className="account-item__verification">
															<span className="account-item__verification___status">
																<span className={statusClassNames[user.status_id]}>
																	{statusNames[user.status_id]}
																</span>
															</span>
															<button
																type="button"
																onClick={handlerShowDetails}
																className={`btn-details-${showDetails ? 'up' : 'down'}`}
															>
																Details
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="16"
																	height="17"
																	viewBox="0 0 16 17"
																	fill="none"
																>
																	<path
																		d="M11.3337 7.16406L8.35388 10.1438C8.15862 10.3391 7.84203 10.3391 7.64677 10.1438L4.66699 7.16406"
																		stroke="#0071AB"
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
															</button>
														</span>
													</div>
													{showDetails && (
														<>
															<div className="account-list-item--mb-32 account-list-details">
																<div className="account-list-item__name">
																	<p>Attachment</p>
																</div>
																<div className="account-list-item__info account-list-item__info--type2">
																	<div className="account-list-item__info-left">
																		<div className="verification-attachment">
																			{user.documents && user.documents.length ? (
																				user.documents.map((el, idx) => (
																					<div
																						key={idx.toString()}
																						className="verification-attachment__item"
																					>
																						<a
																							href={el.path}
																							target="_blank"
																							rel="noreferrer"
																							download
																						>
																							{el.path}
																						</a>
																					</div>
																				))
																			) : (
																				<p>No data</p>
																			)}
																		</div>
																		<div className="account-list_100">
																			<div className="account-list-item__name">
																				<p className="account-list-item__name--color">
																					Change status
																				</p>
																				{/* <div className="account-checkbox mb-4">
																					<span>Unverified / Verified</span>
																					<div className="switch">
																						<label className="switch__label">
																							<input
																								// disabled={!editMode}
																								onChange={handleVerifiedChange}
																								checked={verified}
																								type="checkbox"
																								className="hidden"
																								name="user_login_notification"
																							/>
																							<span className="switch__toggler" />
																						</label>
																					</div>
																				</div> */}
																				<div className="account-checkbox mb-4">
																					<span>Approve</span>
																					<div className="switch">
																						<label className="switch__label">
																							<input
																								onChange={handleVerifiedChange}
																								checked={verified}
																								type="checkbox"
																								className="hidden"
																								name="user_login_notification"
																							/>
																							<span className="switch__toggler" />
																						</label>
																					</div>
																				</div>
																				<div className="account-checkbox">
																					<span>Reject</span>
																					<div className="switch">
																						<label className="switch__label">
																							<input
																								onChange={handleRejectedChange}
																								checked={rejected}
																								type="checkbox"
																								className="hidden"
																								name="user_login_notification"
																							/>
																							<span className="switch__toggler" />
																						</label>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</>
													)}
												</div>
											</div>
										</div>
									</div>
								</Form>
							);
						}}
					</Formik>
				</>
			)}
		</>
	);
};

export default AccountTabView;
